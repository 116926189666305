<template>
	<div>
		<searchCard>
			<div class="flex">
				<div class="w-1/6 pr-4">
					<h6>유형</h6>
					<vSelect v-model="opt.domain" :clearable="false" :options="domainOpts" />
				</div>

				<div class="w-1/6 pr-4">
					<h6>카테고리</h6>
					<vSelect v-model="opt.category" :clearable="false" :options="categoryOpts" />
				</div>

				<div class="w-2/6">
					<h6>검색어</h6>
					<div class="flex">
						<b-form-input
							class="mr-2"
							v-model.trim="opt.searchValue"
							name="keyword"
							placeholder="검색어"
							@keyup.enter="search"
						/>
						<b-button class="w-40 px-6" @click="search">검색</b-button>
					</div>
				</div>
			</div>
		</searchCard>

		<b-card>
			<tb :inf="inf" :res="res" />
		</b-card>
	</div>
</template>

<script>
const defOpt = {
	domain: '',
	category: '',
	searchValue: '',
}

export default {
	data() {
		return {
			domainOpts: [],
			categoryOpts: [],
			opt: {},
			defOpt,

			inf: [
				{ title: 'No.', model: 'idx', size: 2 },
				{ title: '유형', key: 'domainName', size: 2 },
				{
					title: '카테고리',
					key: 'categoryTitle',
					size: 3,
				},
				{ title: '메모대상', key: 'name', size: 6 },
				{ title: '내용', key: 'comment', size: 8, notCenter: 1 },
				{ title: '작성자', key: 'adminName', size: 2 },
				{ title: '작성일시', key: 'createDt', size: 2, sortable: 1 },
				{ title: '수정일시', key: 'updateDt', size: 2, sortable: 1 },
			],
			lastOpt: {},
			res: {},
		}
	},
	methods: {
		search() {
			const opt = this.opt,
				domain = getDropdownValue(opt.domain),
				category = getDropdownValue(opt.category),
				data = {
					//domain: this.domain,
					//identifier: this.identifier,
					paging: getPaging(),
					orderBy: getOrder('createDt'),
				}

			if (domain) data.domain = domain
			if (category) data.category = category
			if (opt.searchValue) data.searchValue = opt.searchValue

			this.lastOpt = data
			this.changePage()
		},
		changePage(page = 1, ex = false) {
			this.lastOpt.paging.pageNo = page - 1
			postApi('api/backoffice/memoSelect/memoList', addExcelData(this, ex)).then(res => {
				if (!ex) {
					res.list.map(v => {
						v.createDt = v.createDt.substr(0, 10)
						v.updateDt = v.updateDt.substr(0, 10)

						//메모 카테고리 적용
						//v.memo = v.category != 'M1' ? `[${v.categoryTitle}]\r\n` : ''
						//v.memo += v.comment
						return v
					})
					this.res = res
				} else alert.excel()
			})
		},
	},
	created() {
		getConfig('memo_category, memo_domain').then(res => {
			this.categoryOpts = res.memo_category
			defOpt.category = this.categoryOpts[0]

			this.domainOpts = res.memo_domain
			defOpt.domain = this.domainOpts[0]
			this.opt = { ...defOpt }
			this.search()
		})
	},
}
</script>
